.chat-container {
    display: flex;
    flex-direction: column;
    height: 30vh;
  }
  
.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    max-height: 70vh; /* Adjust the height as needed */
  }
  
  .message {
    clear: both;
    overflow: auto;
    margin-bottom: 10px;
    padding: 8px; /* Adding padding around the text */
    border-radius: 10px;
  }
  
  .message-text {
    display: inline-block; /* Ensure text and avatar are inline */
  }
  
  .avatar {
    display: inline-block; /* Ensure avatar is inline */
    vertical-align: middle; /* Align avatar vertically */
    margin-right: 8px;
  }
  
  .message.left {
    float: left;
    background-color: #f0f0f0;
  }
  
  .message.right {
    float: right;
    background-color: #007aff;
    color: white;
  }
  
  .chat-input {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  input[type="text"] {
    flex: 1;
    padding: 8px;
    border-radius: 20px;
    border: 1px solid #d0d0d0;
    margin-right: 10px;
  }
  
  button {
    padding: 8px 16px;
    border-radius: 20px;
    background-color: #007aff;
    color: white;
    border: none;
    cursor: pointer;
  }
  